// src/components/crearPaciente/TransferListContenidos.js

import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Box,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";

const TransferListContenidos = ({
  allContenidos = [],
  selectedContenidos = {},
  setSelectedContenidos = () => {},
  searchTerm,
  setSearchTerm,
}) => {
  // Filtrar contenidos según el término de búsqueda
  const filteredContenidos = allContenidos.filter((contenido) =>
    contenido.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Ordenar contenidos para mostrar primero los seleccionados
  const sortedContenidos = filteredContenidos.slice().sort((a, b) => {
    const aSelected = selectedContenidos[a._id?.$oid || a._id] ? 1 : 0;
    const bSelected = selectedContenidos[b._id?.$oid || b._id] ? 1 : 0;
    return bSelected - aSelected; // Los seleccionados van primero
  });

  // Manejar selección/desselección
  const handleToggle = (id) => {
    setSelectedContenidos({
      ...selectedContenidos,
      [id]: !selectedContenidos[id],
    });
  };

  return (
    <Box>
      {/* Buscador de Contenidos */}
      <TextField
        fullWidth
        placeholder="Buscar contenidos..."
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Divider sx={{ mb: 2 }} />
      {/* Lista de Contenidos */}
      <List
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
        }}
      >
        {sortedContenidos.map((contenido) => {
          const id = contenido._id?.$oid || contenido._id;
          return (
            <ListItem
              key={id}
              button
              onClick={() => handleToggle(id)}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingY: 1,
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              <Checkbox
                checked={!!selectedContenidos[id]}
                tabIndex={-1}
                disableRipple
                color="primary"
              />
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: selectedContenidos[id] ? "bold" : "regular",
                    }}
                  >
                    {contenido.nombre}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
        {sortedContenidos.length === 0 && (
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ padding: 2 }}
          >
            No hay contenidos disponibles.
          </Typography>
        )}
      </List>
    </Box>
  );
};

TransferListContenidos.propTypes = {
  allContenidos: PropTypes.array.isRequired,
  selectedContenidos: PropTypes.object.isRequired,
  setSelectedContenidos: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default TransferListContenidos;
