import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Grid,
  Collapse,
} from "@mui/material";

const Paso6HablameDeTi = ({ formValues, onChange, errors }) => {
  const [showExerciseFields, setShowExerciseFields] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
    setShowExerciseFields(value === "si");

    if (value === "no") {
      // Resetear campos si selecciona "No"
      onChange("diasEjercicio", "");
      onChange("horasEjercicio", "");
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "primary.main" }}
      >
        Háblame de ti
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ marginBottom: 3 }}
      >
        Cuéntame más sobre tus hábitos, objetivos y alimentación diaria para
        conocerte mejor y personalizar tu plan.
      </Typography>

      {/* Input: ¿A qué te dedicas? */}
      <TextField
        label="¿A qué te dedicas?"
        name="ocupacionTexto"
        value={formValues.ocupacionTexto || ""}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
        placeholder="Describe tu ocupación o actividad laboral."
        sx={{ marginBottom: 3 }}
        error={Boolean(errors.ocupacionTexto)}
        helperText={errors.ocupacionTexto}
      />

      {/* RadioButtons: ¿Aparte de tu trabajo haces ejercicio? */}
      <FormControl sx={{ marginBottom: 2 }}>
        <FormLabel
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "primary.main",
            marginBottom: 1,
          }}
        >
          ¿Aparte de tu trabajo haces ejercicio?
        </FormLabel>
        <RadioGroup
          row
          name="preguntaEjercicio"
          value={formValues.preguntaEjercicio || ""}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="si"
            control={<Radio />}
            label="Sí"
            sx={{ marginRight: 3 }}
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="No"
            sx={{ marginRight: 3 }}
          />
        </RadioGroup>
        {errors.preguntaEjercicio && (
          <Typography color="error">{errors.preguntaEjercicio}</Typography>
        )}
      </FormControl>

      {/* Campos adicionales si selecciona "Sí" */}
      <Collapse in={showExerciseFields}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {/* Select: ¿Cuántos días a la semana haces ejercicio? */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={Boolean(errors.diasPorSemanaEjercicio)}
            >
              <FormLabel>Días a la semana</FormLabel>
              <Select
                name="diasPorSemanaEjercicio"
                value={formValues.diasPorSemanaEjercicio || ""}
                onChange={handleInputChange}
                displayEmpty
                variant="outlined"
              >
                {[...Array(7)].map((_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
              {errors.diasEjercicio && (
                <Typography color="error">
                  {errors.diasPorSemanaEjercicio}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Select: Horas por día */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={Boolean(errors.horasPorDiaEjercicio)}>
              <FormLabel>Horas por día</FormLabel>
              <Select
                name="horasPorDiaEjercicio"
                value={formValues.horasPorDiaEjercicio || ""}
                onChange={handleInputChange}
                displayEmpty
                variant="outlined"
              >
                {[...Array(8)].map((_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
              {errors.horasPorDiaEjercicio && (
                <Typography color="error">
                  {errors.horasPorDiaEjercicio}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Collapse>

      {/* Input: Cuéntame que comes normalmente */}
      <TextField
        label="Cuéntame que comes normalmente en tu día a día y en qué horarios. Sé lo más específico posible."
        name="alimentacionCotidiana"
        value={formValues.alimentacionCotidiana || ""}
        onChange={handleInputChange}
        multiline
        rows={5}
        fullWidth
        sx={{ marginBottom: 3 }}
        variant="outlined"
        placeholder="Describe tus comidas habituales, horarios y preferencias alimenticias."
        error={Boolean(errors.alimentacionCotidiana)}
        helperText={errors.alimentacionCotidiana}
      />

      {/* Input: Cuéntame que te interesa lograr */}
      <TextField
        label="Cuéntame que te interesa lograr con la dieta y cuáles son tus objetivos."
        name="objetivosTexto"
        value={formValues.objetivosTexto || ""}
        onChange={handleInputChange}
        multiline
        rows={5}
        fullWidth
        variant="outlined"
        placeholder="Escribe tus objetivos, metas y qué esperas obtener de este proceso."
        error={Boolean(errors.objetivosTexto)}
        helperText={errors.objetivosTexto}
      />
    </Box>
  );
};

export default Paso6HablameDeTi;
