import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Fade,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
} from "@mui/material";
import BiometriaHematica from "./analisis/BiometriaHematica";
import PerfilLipidico from "./analisis/PerfilLipidico";
import QuimicaSanguinea from "./analisis/QuimicaSanguinea";
import PruebasGlucosa from "./analisis/PruebasGlucosa";
import ExamenGeneralOrina from "./analisis/ExamenGeneralOrina";
import PlieguesCutaneos from "./PlieguesCutaneos";
import Antropometria from "./Antropometria";
import IndicesCorporales from "./IndicesCorporales";

const Paso4ComposicionFisica = forwardRef(
  (
    {
      formValues,
      formValuesBasic,
      onChange,
      formValuesGlucosa,
      onChangeGlucosa,
    },
    ref
  ) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [submenuTab, setSubmenuTab] = useState(0);
    const [showAdditionalMenu, setShowAdditionalMenu] = useState(false);

    const handleTabChange = (event, newValue) => {
      setCurrentTab(newValue);
      setSubmenuTab(0);
    };

    const handleSubmenuTabChange = (event, newValue) => {
      setSubmenuTab(newValue);
    };

    const handleRadioChange = (event) => {
      setShowAdditionalMenu(event.target.value === "si");
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        return true;
      },
    }));

    return (
      <>
        <Fade in={true}>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: 3,
                fontWeight: "bold",
                color: "primary.main",
              }}
            >
              Composición Física
            </Typography>
          </Box>
        </Fade>

        <Box sx={{ width: "100%" }}>
          {/* Menú Principal */}
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            sx={{ marginBottom: 3 }}
          >
            <Tab label="Perímetros Corporales" />
            <Tab label="Pliegues Cutáneos" />
          </Tabs>

          <Box>
            {currentTab === 0 && (
              <Antropometria
                formValues={formValues}
                onChange={onChange}
                formValuesBasic={formValuesBasic}
              />
            )}
            {currentTab === 1 && (
              <PlieguesCutaneos formValues={formValues} onChange={onChange} />
            )}
          </Box>

          {/* Radio para Información Adicional */}
          <Box sx={{ marginTop: 4, marginBottom: 2 }}>
            <Typography variant="h6" sx={{ color: "text.primary", mb: 1 }}>
              ¿Tienes información adicional sobre algún estudio sanguineo o de
              orina?
            </Typography>
            <RadioGroup
              row
              value={showAdditionalMenu ? "si" : "no"}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="si" control={<Radio />} label="Sí" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>

          {/* Menú Adicional */}
          <Collapse in={showAdditionalMenu} timeout="auto" unmountOnExit>
            <Tabs
              value={submenuTab}
              onChange={handleSubmenuTabChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              sx={{ marginBottom: 2 }}
            >
              <Tab label="Índices Corporales" />
              <Tab label="Biometría Hemática" />
              <Tab label="Perfil Lipídico" />
              <Tab label="Química Sanguínea" />
              <Tab label="Pruebas de Glucosa" />
              <Tab label="Examen General de Orina" />
            </Tabs>

            <Box>
              {submenuTab === 0 && (
                <IndicesCorporales
                  formValues={formValues}
                  onChange={onChange}
                />
              )}
              {submenuTab === 1 && (
                <BiometriaHematica
                  formValues={formValues}
                  onChange={onChange}
                />
              )}
              {submenuTab === 2 && (
                <PerfilLipidico formValues={formValues} onChange={onChange} />
              )}
              {submenuTab === 3 && (
                <QuimicaSanguinea
                  formValues={formValuesGlucosa}
                  onChange={onChangeGlucosa}
                />
              )}
              {submenuTab === 4 && (
                <PruebasGlucosa formValues={formValues} onChange={onChange} />
              )}
              {submenuTab === 5 && (
                <ExamenGeneralOrina
                  formValues={formValues}
                  onChange={onChange}
                />
              )}
            </Box>
          </Collapse>
        </Box>
      </>
    );
  }
);

export default Paso4ComposicionFisica;
