import React from "react";
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@mui/material";

const QuimicaSanguinea = ({ formValues, onChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const today = new Date().toISOString().split("T")[0]; // Fecha actual

  const inputs = [
    { label: "Glucosa", name: "glucosa", units: ["mg/dL"] },
    { label: "Creatinina", name: "creatinina", units: ["mg/dL", "µmol/L"] },
    { label: "Albúmina", name: "albumina", units: ["g/dL", "g/L"] },
    { label: "Urea", name: "urea", units: ["mg/dL", "mmol/L"] },
    { label: "Ácido Úrico", name: "acidoUrico", units: ["mg/dL"] },
    { label: "Hierro", name: "hierro", units: ["μg/dL", "µmol/L"] },
    {
      label: "Glucosa en Plasma",
      name: "glucosaPlasma",
      units: ["mg/dL", "mmol/L"],
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Fecha"
            variant="outlined"
            name="fechaQuimicaSanguinea"
            value={formValues.fechaQuimicaSanguinea || ""}
            onChange={handleInputChange}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true, // Asegura que el label se mantenga arriba
            }}
            inputProps={{
              max: today, // Evitar fechas futuras
            }}
            sx={{
              "& input[type='date']::-webkit-calendar-picker-indicator": {
                cursor: "pointer", // Cursor pointer solo en el icono del calendario
              },
            }}
          />
        </Grid>
        {inputs.map((input, index) => (
          <React.Fragment key={index}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={8} md={4}>
                <TextField
                  label={input.label}
                  variant="outlined"
                  name={input.name}
                  value={formValues[input.name] || ""}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink={true}
                    sx={{
                      backgroundColor: "white",
                      padding: "0 4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Unidad
                  </InputLabel>
                  <Select
                    name={`${input.name}Medida`}
                    value={formValues[`${input.name}Medida`] || input.units[0]}
                    onChange={handleInputChange}
                    input={<OutlinedInput notched label="Medida" />}
                  >
                    {input.units.map((unit, idx) => (
                      <MenuItem key={idx} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default QuimicaSanguinea;
