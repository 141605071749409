import React, { useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  TextField,
  FormControlLabel,
  Switch, // Importado Switch en lugar de Checkbox
  Checkbox,
  Card,
  CardContent,
  Box,
  Grid,
  Snackbar,
  Alert,
  Divider,
} from "@mui/material";
import TransferListContenidos from "./TransferListContenidos"; // Asegúrate de que la ruta es correcta
import { patologias, gruposAlergiasList } from "./constants"; // Importar constantes
import contenidos from "./contenidos/contenidos.json"; // Importar contenidos

const InformacionSalud = forwardRef((props, ref) => {
  const {
    selectedPatologias,
    onPatologiaChange,
    selectedGruposAlergias,
    onGrupoAlergiaChange,
    selectedContenidos,
    onContenidoChange,
    patologiaSearch,
    setPatologiaSearch,
    contenidoSearch,
    setContenidoSearch,
  } = props;

  // Estado para Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "warning",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Definir categorías con restricciones de selección
  const categoriasRestrictivas = {
    Obesidad: [
      "OBESIDAD_GRADO_1",
      "OBESIDAD_GRADO_2",
      "OBESIDAD_GRADO_3",
      "sobrepeso",
    ],
    Anemia: ["anemia_leve", "anemia_moderada", "anemia_severa", "ANEMIA"],
    Desnutricion: [
      "desnutricion",
      "desnutricion_cronica",
      "desnutricion_leve",
      "desnutricion_moderada",
      "desnutricion_severa",
    ],
  };

  const getCategoria = (clave) => {
    for (const [categoria, claves] of Object.entries(categoriasRestrictivas)) {
      if (claves.includes(clave)) return categoria;
    }
    return null;
  };

  const handlePatologiaChangeWithValidation = (clave, checked) => {
    const categoria = getCategoria(clave);
    if (categoria && checked) {
      const otrasPatologias = categoriasRestrictivas[categoria].filter(
        (p) => p !== clave && selectedPatologias[p]
      );
      if (otrasPatologias.length > 0) {
        setSnackbar({
          open: true,
          message: `Solo puedes seleccionar una patología en la categoría "${categoria}".`,
          severity: "warning",
        });
        return;
      }
    }

    onPatologiaChange({
      ...selectedPatologias,
      [clave]: checked,
    });
  };

  const validate = () => {
    return true;
  };

  useImperativeHandle(ref, () => ({
    validate,
    getData: () => ({
      patologias: selectedPatologias,
      gruposAlergias: selectedGruposAlergias,
      contenidos: selectedContenidos,
    }),
    submit: () => {
      setSnackbar({
        open: true,
        message: "Información de salud guardada exitosamente!",
        severity: "success",
      });
    },
  }));

  const sortedPatologias = patologias
    .filter((patologia) =>
      patologia.nombre.toLowerCase().includes(patologiaSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = selectedPatologias[a.clave] ? 1 : 0;
      const bSelected = selectedPatologias[b.clave] ? 1 : 0;
      return bSelected - aSelected;
    });

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          Información de Salud
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Selecciona las patologías, alergias y contenidos relacionados con tu
          salud y gustos. Esto nos permitirá personalizar tu tratamiento y
          recomendaciones.
        </Typography>
      </Box>

      <Grid container spacing={4} alignItems="stretch">
        {/* Sección de Grupos de Alergias */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              borderRadius: 2,
              border: "1px solid #e0e0e0",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent
              sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "medium", color: "text.primary" }}
              >
                Eliminar grupos de alimentos?
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                gutterBottom
                sx={{ mb: 2 }}
              >
                De los siguientes grupos de alimentos, marca alguno que no
                quieras consumir o que seas alergico.
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  maxHeight: "300px",
                }}
              >
                {gruposAlergiasList.map((grupo) => (
                  <FormControlLabel
                    key={grupo.clave}
                    control={
                      <Switch
                        checked={selectedGruposAlergias[grupo.clave] || false}
                        onChange={(e) =>
                          onGrupoAlergiaChange({
                            ...selectedGruposAlergias,
                            [grupo.clave]: e.target.checked,
                          })
                        }
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: selectedGruposAlergias[grupo.clave]
                            ? "bold"
                            : "regular",
                        }}
                      >
                        {grupo.nombre}
                      </Typography>
                    }
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  />
                ))}
                {gruposAlergiasList.length === 0 && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{ padding: 2 }}
                  >
                    No hay grupos de alergias disponibles.
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Sección de Contenidos */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: 2, border: "1px solid #e0e0e0" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "medium", color: "text.primary" }}
              >
                Eliminar alimentos individuales
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                gutterBottom
                sx={{ mb: 2 }}
              >
                Si alguno no pertenece a un grupo, eliminalos aqui
                individualmente.
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <TransferListContenidos
                allContenidos={contenidos}
                selectedContenidos={selectedContenidos}
                setSelectedContenidos={onContenidoChange}
                searchTerm={contenidoSearch}
                setSearchTerm={setContenidoSearch}
              />
            </CardContent>
          </Card>
        </Grid>
        {/* Sección de Patologías */}
        <Grid item xs={12}>
          <Card
            sx={{
              borderRadius: 2,
              border: "1px solid #e0e0e0",
              height: "100%", // Hace que la tarjeta ocupe toda la altura disponible
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent
              sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            >
              {/* Permite que el contenido crezca y se distribuya verticalmente */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "medium", color: "text.primary" }}
              >
                Patologías
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                gutterBottom
                sx={{ mb: 2 }}
              >
                Si estas seguro que tienes alguna patología de las que vienen en
                el listado, marcala, si no viene, me lo describes mas adelante.
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <TextField
                fullWidth
                placeholder="Buscar patologías..."
                variant="outlined"
                size="small"
                value={patologiaSearch}
                onChange={(e) => setPatologiaSearch(e.target.value)}
                sx={{ mb: 3 }}
              />
              <Divider sx={{ mb: 2 }} />
              <Box
                sx={{
                  flexGrow: 1, // Permite que el listado ocupe el espacio restante
                  overflowY: "auto", // Habilita el scroll
                  maxHeight: "300px", // Ajusta la altura para mostrar aproximadamente 10 elementos
                }}
              >
                {sortedPatologias.map((patologia) => (
                  <FormControlLabel
                    key={patologia.clave}
                    control={
                      <Checkbox
                        checked={selectedPatologias[patologia.clave] || false}
                        onChange={(e) =>
                          handlePatologiaChangeWithValidation(
                            patologia.clave,
                            e.target.checked
                          )
                        }
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: selectedPatologias[patologia.clave]
                            ? "bold"
                            : "regular",
                        }}
                      >
                        {patologia.nombre}
                      </Typography>
                    }
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  />
                ))}
                {sortedPatologias.length === 0 && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{ padding: 2 }}
                  >
                    No se encontraron patologías.
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

InformacionSalud.propTypes = {
  selectedPatologias: PropTypes.object.isRequired,
  onPatologiaChange: PropTypes.func.isRequired,
  selectedGruposAlergias: PropTypes.object.isRequired,
  onGrupoAlergiaChange: PropTypes.func.isRequired,
  selectedContenidos: PropTypes.object.isRequired,
  onContenidoChange: PropTypes.func.isRequired,
  patologiaSearch: PropTypes.string,
  setPatologiaSearch: PropTypes.func,
  contenidoSearch: PropTypes.string,
  setContenidoSearch: PropTypes.func,
};

export default InformacionSalud;
