import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const ExamenGeneralOrina = ({ formValues, onChange }) => {
  const [checks, setChecks] = useState({
    cuerposCetonicos: false,
    pigmentosBiliares: false,
    nitritos: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const handleCheckChange = (name) => {
    setChecks((prev) => ({ ...prev, [name]: !prev[name] }));
    onChange(name, !checks[name]);
  };

  const today = new Date().toISOString().split("T")[0]; // Fecha actual para evitar fechas futuras

  const inputs = [
    {
      label: "Glucosa en orina",
      name: "glucosaOrina",
      units: ["mg/dL"],
    },
    {
      label: "Proteínas en orina",
      name: "proteinasOrina",
      units: ["mg/dL", "mg/24h"],
    },
    {
      label: "Hemoglobina en orina",
      name: "hemoglobinaOrina",
      units: ["CHP", "mg/dL"],
    },
    {
      label: "Urobilinógeno",
      name: "urobilinogeno",
      units: ["mg/dL"],
    },
  ];

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          marginBottom: 3,
          fontWeight: "bold",
          color: "primary.main",
        }}
      >
        Examen General de Orina
      </Typography>
      <Grid container spacing={2}>
        {/* Fecha */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Fecha"
            variant="outlined"
            name="fechaEGO"
            value={formValues.fechaEGO || ""}
            onChange={handleInputChange}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true, // Asegura que el label se mantenga arriba
            }}
            inputProps={{
              max: today, // Evitar fechas futuras
            }}
            sx={{
              "& input[type='date']::-webkit-calendar-picker-indicator": {
                cursor: "pointer", // Cursor pointer solo en el ícono del calendario
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} />
        {/* Inputs con select */}
        {inputs.map((input, index) => (
          <Grid
            container
            item
            xs={12}
            spacing={2}
            alignItems="center"
            key={index}
          >
            <Grid item xs={6} md={4}>
              <TextField
                label={input.label}
                variant="outlined"
                name={input.name}
                value={formValues[input.name] || ""}
                onChange={handleInputChange}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  shrink={true}
                  sx={{
                    backgroundColor: "white",
                    padding: "0 4px",
                    marginLeft: "-4px",
                  }}
                >
                  Unidad
                </InputLabel>
                <Select
                  name={`${input.name}Medida`}
                  value={formValues[`${input.name}Medida`] || input.units[0]}
                  onChange={handleInputChange}
                  input={<OutlinedInput notched label="Medida" />}
                >
                  {input.units.map((unit, idx) => (
                    <MenuItem key={idx} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ))}
        {/* Checkboxes */}
        {[
          { label: "Cuerpos Cetónicos", name: "cuerposCetonicos" },
          { label: "Pigmentos Biliares", name: "pigmentosBiliares" },
          { label: "Nitritos", name: "nitritos" },
        ].map((check, index) => (
          <Grid item xs={12}>
            <Grid item xs={12} sm={6} key={index}>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: "primary.light",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checks[check.name]}
                      onChange={() => handleCheckChange(check.name)}
                    />
                  }
                  label={check.label}
                  sx={{ fontWeight: "bold", color: "text.primary" }}
                />
                {checks[check.name] && (
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: 1,
                      textAlign: "center",
                      color: "primary.main",
                      fontWeight: "bold",
                    }}
                  >
                    Presencia
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ExamenGeneralOrina;
