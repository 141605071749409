import React from "react";
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@mui/material";

const PerfilLipidico = ({ formValues, onChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const today = new Date().toISOString().split("T")[0]; // Fecha actual para evitar fechas futuras

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Fecha - fila completa */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Fecha"
            variant="outlined"
            name="fechaPerfilLipidico"
            value={formValues.fechaPerfilLipidico || ""}
            onChange={handleInputChange}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true, // Asegura que el label se mantenga arriba
            }}
            inputProps={{
              max: today, // Evitar fechas futuras
            }}
            sx={{
              "& input[type='date']::-webkit-calendar-picker-indicator": {
                cursor: "pointer", // Cursor pointer solo en el icono del calendario
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} /> {/* Espacio vacío */}
        {/* Inputs con Select */}
        {[
          { label: "Triglicéridos", name: "trigliceridos" },
          { label: "Colesterol Total", name: "colesterolTotal" },
          { label: "Colesterol LDL", name: "colesterolLDL" },
          { label: "Colesterol HDL", name: "colesterolHDL" },
          { label: "Colesterol VLDL", name: "colesterolVLDL" },
        ].map((item, index) => (
          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12} md={6} key={index} spacing={2}>
              <Grid item xs={8}>
                <TextField
                  label={item.label}
                  variant="outlined"
                  name={item.name}
                  value={formValues[item.name] || ""}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink={true}
                    sx={{
                      backgroundColor: "white",
                      padding: "0 4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Unidad
                  </InputLabel>
                  <Select
                    name={`${item.name}Medida`}
                    value={formValues[`${item.name}Medida`] || "mg/dL"}
                    onChange={handleInputChange}
                    input={<OutlinedInput notched label="Medida" />}
                  >
                    <MenuItem value="mg/dL">mg/dL</MenuItem>
                    <MenuItem value="mmol/L">mmol/L</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PerfilLipidico;
