import React from "react";
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@mui/material";

const BiometriaHematica = ({ formValues, onChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const today = new Date().toISOString().split("T")[0]; // Fecha actual para evitar fechas futuras

  return (
    <Box>
      <Grid container spacing={2} alignItems="stretch">
        {/* Fecha */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Fecha Biometría Hemática"
              variant="outlined"
              name="biometriaHematicaFecha"
              value={formValues.biometriaHematicaFecha || ""}
              onChange={handleInputChange}
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true, // Asegura que el label se mantenga arriba
              }}
              inputProps={{
                max: today, // Evitar fechas futuras
              }}
              sx={{
                "& input[type='date']::-webkit-calendar-picker-indicator": {
                  cursor: "pointer", // Cursor pointer solo en el icono del calendario
                },
              }}
            />
          </Grid>
        </Grid>
        {/* Hemoglobina con Unidad */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Hemoglobina"
              variant="outlined"
              name="hemoglobina"
              value={formValues.hemoglobina || ""}
              onChange={handleInputChange}
              type="number"
              fullWidth
              sx={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth variant="outlined" sx={{ height: "100%" }}>
              <InputLabel
                shrink={true}
                sx={{
                  backgroundColor: "white",
                  padding: "0 4px",
                  marginLeft: "-4px",
                }}
              >
                Unidad
              </InputLabel>
              <Select
                name="hemoglobinaUnidad"
                value={formValues.hemoglobinaUnidad}
                onChange={(e) => handleInputChange(e)}
                input={<OutlinedInput notched label="Unidad" />}
              >
                <MenuItem value="g/dL">g/dL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BiometriaHematica;
