// src/components/crearPaciente/PantallaRegistroPacienteOnline.js

import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Paper,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import HomeIcon from "@mui/icons-material/Home";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ForumIcon from "@mui/icons-material/Forum";
import ScienceIcon from "@mui/icons-material/Science";
import Paso1InformacionBasica from "./Paso1InformacionBasica";
import InformacionSalud from "./InformacionSalud";
import Paso4ComposicionFisica from "./Paso4ComposicionFisica";
import Paso6HablameDeTi from "./Paso6HablameDeTi";
import Bienvenida from "./Bienvenida";

import { patologias, gruposAlergiasList } from "./constants";
import contenidos from "./contenidos/contenidos.json";
import { crearPaciente } from "../../services/patientService"; // Importar el servicio

import Swal from "sweetalert2"; // Importar SweetAlert2
import { useNavigate } from "react-router-dom"; // Importar useNavigate para redirección

import validator from "validator";

// Iconos personalizados para los pasos
const stepIcons = {
  1: <HomeIcon />,
  2: <ContactMailIcon />,
  3: <FavoriteIcon />,
  4: <ScienceIcon />,
  5: <ForumIcon />,
  // Eliminado el icono para el paso 6
};

// Crear el conector personalizado usando styled
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[400], // Color inactivo
    borderRadius: 1,
  },
  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: {
    backgroundColor: theme.palette.primary.main, // Color activo
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    backgroundColor: theme.palette.primary.main, // Color completado
  },
}));

// Crear el ícono de paso personalizado usando styled
const StepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[400], // Color inactivo
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main, // Color activo
    color: "#fff",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main, // Color completado
  }),
}));

// Componente personalizado para los íconos de los pasos
function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  return (
    <StepIconRoot ownerState={{ completed, active }}>
      {stepIcons[String(icon)]}
    </StepIconRoot>
  );
}

const PantallaRegistroPacienteOnline = () => {
  const [dataNutriologoSubdominio, setDataNutriologoSubdominio] =
    useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Inicializar useNavigate

  const fetchData = async () => {
    try {
      // Extraer el subdominio dinámicamente
      const hostname = window.location.hostname; // Ejemplo: subdominio.miapp.com
      const subdominio = hostname.split(".")[0]; // Obtiene el primer segmento del hostname

      if (!subdominio) {
        throw new Error("No se pudo determinar el subdominio.");
      }

      const response = await fetch(
        `/api/nutriologo/subdomain/info?subdominio=${subdominio}`
      );

      if (!response.ok) {
        throw new Error("Error al obtener los datos del backend");
      }
      const result = await response.json();
      setDataNutriologoSubdominio(result); // Guarda los datos recibidos
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // Marca la carga como completada
    }
  };

  useEffect(() => {
    fetchData(); // Llama a la función al montar el componente
  }, []);

  // Definir los pasos del stepper (eliminado "Confirmación")
  const steps = [
    "Bienvenido",
    "Información básica",
    "Información de Salud",
    "Composición",
    "Háblame de ti",
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({}); // Estado para errores

  const [formValuesBienvenida, setFormValuesBienvenida] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChangeBienvenida = (name, value) => {
    setFormValuesBienvenida((prev) => ({ ...prev, [name]: value }));
  };

  // Estados en el padre para no perder los datos
  const [formValuesBasic, setFormValuesBasic] = useState({
    nombrePaciente: "",
    apellidoPaciente: "",
    edad: "",
    telefono: "",
    pesoPaciente: "",
    estatura: "",
    fechaNacimiento: "",
    sexo: "",
    embarazoActivo: "",
    tiempoEmbarazo: "",
  });

  const [formValuesSalud, setFormValuesSalud] = useState({
    patologias: patologias.reduce((acc, patologia) => {
      acc[patologia.clave] = false;
      return acc;
    }, {}),
    gruposAlergias: gruposAlergiasList.reduce((acc, grupo) => {
      acc[grupo.clave] = false;
      return acc;
    }, {}),
    contenidos: contenidos.reduce((acc, contenido) => {
      const id = contenido.nombre || contenido.nombre;
      acc[id] = false;
      return acc;
    }, {}),
  });

  const [formValuesComposicion, setFormValuesComposicion] = useState({
    biometriaHematicaFecha: "",
    hemoglobina: "",

    perfilLipidicoFecha: "",
    trigliceridos: "",
    trigliceridosMedida: "",
    colesterolTotal: "",
    colesterolTotalMedida: "",
    colesterolLDL: "",
    colesterolLDLMedida: "",
    colesterolHDL: "",
    colesterolHDLMedida: "",
    colesterolVLDL: "",
    colesterolVLDLMedida: "",

    quimicaSanguineaFecha: "",
    glucosa: "",
    glucosaMedida: "",
    creatinina: "",
    creatininaMedida: "",
    albumina: "",
    albuminaMedida: "",
    urea: "",
    ureaMedida: "",
    acidoUrico: "",
    acidoUricoMedida: "",
    hierro: "",
    hierroMedida: "",
    glucosaPlasma: "",
    glucosaPlasmaMedida: "",

    examenGeneralOrinaFecha: "",
    glucosaOrina: "",
    glucosaOrinaMedida: "",
    proteinasOrina: "",
    proteinasOrinaMedida: "",
    hemoglobinaOrina: "",
    hemoglobinaOrinaMedida: "",
    urobilinogeno: "",
    urobilinogenoMedida: "",
    cuerposCetonicos: false,
    pigmentosBiliares: false,
    nitritos: false,

    indicesCorporalesFecha: "",
    circunferenciaCintura: "",
    circunferenciaCinturaMedida: "",
    porcentajeGrasa: "",
    porcentajeGrasaMedida: "",
    porcentajeAgua: "",
    porcentajeAguaMedida: "",
    porcentajeMusculo: "",
    porcentajeMusculoMedida: "",
    edadDigestion: "",
    edadDigestionMedida: "",
    grasaVisceral: "",
    grasaVisceralMedida: "",

    antropometriaFecha: "",
    brazo: "",
    brazoMedida: "",
    hombros: "",
    hombrosMedida: "",
    pectoralBusto: "",
    pectoralBustoMedida: "",
    abdomenCintura: "",
    abdomenCinturaMedida: "",
    caderaGluteos: "",
    caderaGluteosMedida: "",
    muslo: "",
    musloMedida: "",
    pantorrilla: "",
    pantorrillaMedida: "",

    plieguesCutaneosFecha: "",
    pectoral: "",
    pectoralMedida: "",
    tricipital: "",
    tricipitalMedida: "",
    bicipital: "",
    bicipitalMedida: "",
    subescapular: "",
    subescapularMedida: "",
    midaxilar: "",
    midaxilarMedida: "",
    suprailiaco: "",
    suprailiacoMedida: "",
    abdominal: "",
    abdominalMedida: "",
    musloMedio: "",
    musloMedioMedida: "",
    pantorrillaMedia: "",
    pantorrillaMediaMedida: "",
  });

  const [formValuesGlucosa, setFormValuesGlucosa] = useState({
    glucosaCualquierHora: "",
    glucosaCualquierHoraFecha: "",
    glucosaCualquierHoraMedida: "",
    glucosaAyuno: "",
    glucosaAyunoFecha: "",
    glucosaAyunoMedida: "",
    hemoglobinaGlucosilada: "",
    hemoglobinaGlucosiladaFecha: "",
    hemoglobinaGlucosiladaMedida: "",
    toleranciaGlucosa: "",
    toleranciaGlucosaFecha: "",
    toleranciaGlucosaMedida: "",
  });

  const [formValuesHablameDeTi, setFormValuesHablameDeTi] = useState({
    ocupacionTexto: "",
    preguntaEjercicio: "",
    diasPorSemanaEjercicio: "",
    horasPorDiaEjercicio: "",
    alimentacionCotidiana: "",
    objetivosTexto: "",
  });

  const handleChangeHablameDeTi = (name, value) => {
    setFormValuesHablameDeTi((prev) => ({ ...prev, [name]: value }));

    // Eliminar errores al interactuar con el campo
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[name];
      return newErrors;
    });

    // Limpiar errores relacionados si selecciona "No" en el radio button
    if (name === "preguntaEjercicio" && value === "no") {
      setFormValuesHablameDeTi((prev) => ({
        ...prev,
        diasPorSemanaEjercicio: "",
        horasPorDiaEjercicio: "",
      }));
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.diasPorSemanaEjercicio;
        delete newErrors.horasPorDiaEjercicio;
        return newErrors;
      });
    }
  };

  const handleChangeGlucosa = (name, value) => {
    setFormValuesGlucosa((prev) => ({ ...prev, [name]: value }));
  };

  // Estados para búsquedas
  const [patologiaSearch, setPatologiaSearch] = useState("");
  const [contenidoSearch, setContenidoSearch] = useState("");

  // Referencias para los pasos
  const paso1Ref = useRef(null);
  const paso2Ref = useRef(null);
  const paso3Ref = useRef(null);
  // No necesitas referencia para Paso6HablameDeTi ya que manejas las validaciones desde el padre

  // Funciones para actualizar estado desde los hijos
  const handleChangeBasic = (name, value) => {
    setFormValuesBasic((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSaludPatologias = (updatedPatologias) => {
    setFormValuesSalud((prev) => ({ ...prev, patologias: updatedPatologias }));
  };

  const handleChangeSaludGruposAlergias = (updatedGruposAlergias) => {
    setFormValuesSalud((prev) => ({
      ...prev,
      gruposAlergias: updatedGruposAlergias,
    }));
  };

  const handleChangeSaludContenidos = (updatedContenidos) => {
    setFormValuesSalud((prev) => ({ ...prev, contenidos: updatedContenidos }));
  };

  const handleChangeComposicion = (name, value) => {
    console.log("Actualizando:", name, "con valor:", value); // Depuración
    setFormValuesComposicion((prev) => ({ ...prev, [name]: value }));
  };

  // Estados para el Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Nuevo estado para la severidad

  const handleNext = () => {
    if (activeStep === 0) {
      if (!validator.isEmail(formValuesBienvenida.email)) {
        Swal.fire("Error", "Ingresa un correo electronico valido", "error");
        return;
      }
      if (
        formValuesBienvenida.password == "" ||
        formValuesBienvenida.password.length < 6
      ) {
        Swal.fire(
          "Error",
          "Ingresa una contraseña mayor a 6 caracteres",
          "error"
        );
        return;
      }

      if (
        formValuesBienvenida.password !== formValuesBienvenida.confirmPassword
      ) {
        Swal.fire("Error", "Las contraseñas no coinciden.", "error");
        return;
      }
    }

    if (activeStep === 1) {
      const isValid = paso1Ref.current?.validate();
      if (!isValid) return;
    }

    if (activeStep === 2) {
      const isValid = paso2Ref.current?.validate();
      if (!isValid) return;
    }

    if (activeStep === 3) {
      const isValid = paso3Ref.current?.validate();
      if (!isValid) return;
    }

    if (activeStep === 4) {
      // Validación para Paso6HablameDeTi
      const validationErrors = validatePaso6HablameDeTi();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setSnackbarMessage("Por favor, corrige los errores en el formulario.");
        setSnackbarSeverity("error"); // Mensaje de error
        setSnackbarOpen(true);
        return;
      }
      setErrors({});
      // En lugar de avanzar al siguiente paso, se finalizará aquí
      return;
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const validatePaso6HablameDeTi = () => {
    const newErrors = {};

    if (
      !formValuesHablameDeTi.ocupacionTexto ||
      formValuesHablameDeTi.ocupacionTexto.trim().length < 4
    ) {
      newErrors.ocupacionTexto = "Debe tener al menos 4 caracteres.";
    }

    if (!formValuesHablameDeTi.preguntaEjercicio) {
      newErrors.preguntaEjercicio = "Debe seleccionar una opción.";
    }

    if (formValuesHablameDeTi.preguntaEjercicio === "si") {
      if (!formValuesHablameDeTi.diasPorSemanaEjercicio) {
        newErrors.diasPorSemanaEjercicio =
          "Debe seleccionar un número de días.";
      }
      if (!formValuesHablameDeTi.horasPorDiaEjercicio) {
        newErrors.horasPorDiaEjercicio = "Debe seleccionar un número de horas.";
      }
    }

    if (
      !formValuesHablameDeTi.alimentacionCotidiana ||
      formValuesHablameDeTi.alimentacionCotidiana.trim().length < 4
    ) {
      newErrors.alimentacionCotidiana = "Debe tener al menos 4 caracteres.";
    }

    if (
      !formValuesHablameDeTi.objetivosTexto ||
      formValuesHablameDeTi.objetivosTexto.trim().length < 4
    ) {
      newErrors.objetivosTexto = "Debe tener al menos 4 caracteres.";
    }

    return newErrors;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Bienvenida
            dataNutriologoSubdominio={dataNutriologoSubdominio}
            formValues={formValuesBienvenida}
            onChange={handleChangeBienvenida}
          />
        );
      case 1:
        return (
          <Paso1InformacionBasica
            ref={paso1Ref}
            formValues={formValuesBasic}
            formValuesEmail={formValuesBienvenida.email}
            onChange={handleChangeBasic}
          />
        );

      case 2:
        return (
          <InformacionSalud
            ref={paso2Ref}
            selectedPatologias={formValuesSalud.patologias}
            selectedGruposAlergias={formValuesSalud.gruposAlergias}
            onPatologiaChange={handleChangeSaludPatologias}
            onGrupoAlergiaChange={handleChangeSaludGruposAlergias}
            selectedContenidos={formValuesSalud.contenidos}
            onContenidoChange={handleChangeSaludContenidos}
            patologiaSearch={patologiaSearch}
            setPatologiaSearch={setPatologiaSearch}
            contenidoSearch={contenidoSearch}
            setContenidoSearch={setContenidoSearch}
          />
        );

      case 3:
        return (
          <Paso4ComposicionFisica
            ref={paso3Ref}
            formValues={formValuesComposicion}
            onChange={handleChangeComposicion}
            formValuesGlucosa={formValuesGlucosa}
            onChangeGlucosa={handleChangeGlucosa}
            formValuesBasic={formValuesBasic}
          />
        );

      case 4:
        return (
          <Paso6HablameDeTi
            formValues={formValuesHablameDeTi}
            onChange={handleChangeHablameDeTi}
            errors={errors}
          />
        );

      default:
        return null;
    }
  };

  // Función para cerrar el Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Nueva función para manejar el envío de datos
  const handleSubmit = async () => {
    setLoading(true); // Mostrar indicador de carga
    try {
      // Preparar todos los datos necesarios para el servicio
      const datosPaciente = {
        formValuesBasic,
        formValuesBienvenida,
        formValuesSalud,
        formValuesComposicion,
        formValuesGlucosa,
        formValuesHablameDeTi,
        dataNutriologoSubdominio,
      };

      // Llamar al servicio para crear el paciente
      const resultado = await crearPaciente(datosPaciente, patologias); // Pasamos la lista completa de patologías

      // Manejar la respuesta exitosa (redireccionar)
      Swal.fire("Éxito", "Paciente creado exitosamente.", "success").then(
        () => {
          navigate("/welcomePage"); // Redireccionar a Bienvenida.js
        }
      );
    } catch (error) {
      // Manejar errores (mostrar mensaje al usuario)
      Swal.fire(
        "Error",
        "Error al crear el paciente. Por favor, intenta nuevamente.",
        "error"
      );
    } finally {
      setLoading(false); // Ocultar indicador de carga
    }
  };

  // Nueva función para manejar la finalización con confirmación
  const handleFinalize = () => {
    // Validar antes de mostrar la confirmación
    const validationErrors = validatePaso6HablameDeTi();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSnackbarMessage("Por favor, corrige los errores en el formulario.");
      setSnackbarSeverity("error"); // Mensaje de error
      setSnackbarOpen(true);
      return;
    }
    setErrors({});

    // Mostrar SweetAlert de confirmación
    Swal.fire({
      title: "Confirmación",
      html: `
          Tus datos estan a punto de enviarse, para que puedas ver tu dieta asignada, se te va a crear una cuenta con el correo: 
          <p><span style="color: rgb(24,118,210,1); font-weight: bold;">
            ${formValuesBienvenida.email}
          </span></p>
          y la contraseña que pusiste al inicio.
      `,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  };

  return (
    <Container maxWidth="lg">
      <Paper
        sx={{
          padding: { xs: 2, sm: 3, md: 4 },
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: 5,
          boxShadow: 3,
          borderRadius: 2,
          width: "100%",
        }}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<ColorlibConnector />} // Usar el conector personalizado
          sx={{ width: "100%" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 4, mb: 2, width: "100%" }}>
          {renderStepContent(activeStep)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              backgroundColor: "grey.300",
              color: "text.primary",
              "&:hover": {
                backgroundColor: "grey.400",
              },
            }}
          >
            Atrás
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "primary.main",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={handleFinalize} // Cambiado a handleFinalize
            >
              Finalizar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                backgroundColor: "primary.main",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              Siguiente
            </Button>
          )}
        </Box>
      </Paper>
      {loading && <LinearProgress sx={{ width: "100%", marginTop: 2 }} />}

      {/* Snackbar para mensajes */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Usar la severidad dinámica
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PantallaRegistroPacienteOnline;
