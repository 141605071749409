import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Paper,
} from "@mui/material";

const PruebasGlucosa = ({ formValues, onChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const today = new Date().toISOString().split("T")[0]; // Fecha actual para evitar fechas futuras

  const inputs = [
    {
      label: "Glucosa a cualquier hora del día",
      name: "glucosaCualquierHora",
      units: ["mg/dL", "mmol/L"],
    },
    {
      label: "Glucosa en ayuno",
      name: "glucosaAyuno",
      units: ["mg/dL"],
    },
    {
      label: "Hemoglobina Glucosilada",
      name: "hemoglobinaGlucosilada",
      units: ["%"],
    },
    {
      label: "Tolerancia Oral a la Glucosa",
      name: "toleranciaOralGlucosa",
      units: ["mg/dL", "mmol/L"],
    },
  ];

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          marginBottom: 3,
          fontWeight: "bold",
          color: "primary.main",
        }}
      >
        Pruebas de Glucosa
      </Typography>
      <Grid container spacing={2}>
        {inputs.map((input, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                borderRadius: 2,
                border: "1px solid",
                borderColor: "primary.light",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  marginBottom: 2,
                  textAlign: "center",
                  color: "text.primary",
                }}
              >
                {input.label}
              </Typography>
              <TextField
                label={`Fecha`}
                variant="outlined"
                name={`fecha${input.name}`}
                value={formValues[`fecha${input.name}`] || ""}
                onChange={handleInputChange}
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true, // Asegura que el label se mantenga arriba
                }}
                inputProps={{
                  max: today, // Evitar fechas futuras
                }}
                sx={{
                  marginBottom: 2,
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    cursor: "pointer", // Cursor pointer solo en el ícono del calendario
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Valor"
                  variant="outlined"
                  name={input.name}
                  value={formValues[input.name] || ""}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                />
                <FormControl
                  fullWidth
                  sx={{ maxWidth: "120px" }}
                  variant="outlined"
                >
                  <InputLabel
                    shrink={true}
                    sx={{
                      backgroundColor: "white",
                      padding: "0 4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Unidad
                  </InputLabel>
                  <Select
                    name={`${input.name}Medida`}
                    value={formValues[`${input.name}Medida`] || input.units[0]}
                    onChange={handleInputChange}
                    input={<OutlinedInput notched label="Medida" />}
                    sx={{ cursor: "pointer" }}
                  >
                    {input.units.map((unit, idx) => (
                      <MenuItem key={idx} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PruebasGlucosa;
