import React, { useState, forwardRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Dialog,
  DialogContent,
} from "@mui/material";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Importar imágenes para ambos géneros
import PectoralMasculino from "../../assets/images/plieguescutaneos/pectoral.png";
import PectoralFemenino from "../../assets/images/plieguescutaneos/pectoralMujer.png";
import TricipitalMasculino from "../../assets/images/plieguescutaneos/tricipital.png";
import TricipitalFemenino from "../../assets/images/plieguescutaneos/tricipitalMujer.png";
import BicipitalMasculino from "../../assets/images/plieguescutaneos/bicipital.png";
import BicipitalFemenino from "../../assets/images/plieguescutaneos/bicipitalMujer.png";
import SubescapularMasculino from "../../assets/images/plieguescutaneos/subescapular.png";
import SubescapularFemenino from "../../assets/images/plieguescutaneos/subescapularMujer.png";
import MidaxilarMasculino from "../../assets/images/plieguescutaneos/midaxilar.png";
import MidaxilarFemenino from "../../assets/images/plieguescutaneos/midaxilarMujer.png";
import SuprailiacoMasculino from "../../assets/images/plieguescutaneos/suprailiaco.png";
import SuprailiacoFemenino from "../../assets/images/plieguescutaneos/suprailiacoMujer.png";
import AbdominalMasculino from "../../assets/images/plieguescutaneos/abdominal.png";
import AbdominalFemenino from "../../assets/images/plieguescutaneos/abdominalMujer.png";
import MusloMedioMasculino from "../../assets/images/plieguescutaneos/musloMedio.png";
import MusloMedioFemenino from "../../assets/images/plieguescutaneos/musloMedioMujer.png";
import PantorrillaMediaMasculino from "../../assets/images/plieguescutaneos/pantorrillaMedia.png";
import PantorrillaMediaFemenino from "../../assets/images/plieguescutaneos/pantorrillaMediaMujer.png";

const PlieguesCutaneos = forwardRef(
  ({ formValues, formValuesBasic, onChange }, ref) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      onChange(name, value);
    };

    const today = new Date().toISOString().split("T")[0]; // Fecha actual para evitar fechas futuras

    // Abrir el Dialog con la imagen seleccionada
    const handleImageClick = (image) => {
      setCurrentImage(image);
      setOpenDialog(true);
    };

    // Cerrar el Dialog
    const handleDialogClose = () => {
      setOpenDialog(false);
    };

    // Imágenes según el género
    const carouselImages = [
      {
        label: "Pectoral",
        src:
          formValuesBasic?.sexo === "femenino"
            ? PectoralFemenino
            : PectoralMasculino,
      },
      {
        label: "Tricipital",
        src:
          formValuesBasic?.sexo === "femenino"
            ? TricipitalFemenino
            : TricipitalMasculino,
      },
      {
        label: "Bicipital",
        src:
          formValuesBasic?.sexo === "femenino"
            ? BicipitalFemenino
            : BicipitalMasculino,
      },
      {
        label: "Subescapular",
        src:
          formValuesBasic?.sexo === "femenino"
            ? SubescapularFemenino
            : SubescapularMasculino,
      },
      {
        label: "Midaxilar",
        src:
          formValuesBasic?.sexo === "femenino"
            ? MidaxilarFemenino
            : MidaxilarMasculino,
      },
      {
        label: "Suprailiaco",
        src:
          formValuesBasic?.sexo === "femenino"
            ? SuprailiacoFemenino
            : SuprailiacoMasculino,
      },
      {
        label: "Abdominal",
        src:
          formValuesBasic?.sexo === "femenino"
            ? AbdominalFemenino
            : AbdominalMasculino,
      },
      {
        label: "Muslo Medio",
        src:
          formValuesBasic?.sexo === "femenino"
            ? MusloMedioFemenino
            : MusloMedioMasculino,
      },
      {
        label: "Pantorrilla Media",
        src:
          formValuesBasic?.sexo === "femenino"
            ? PantorrillaMediaFemenino
            : PantorrillaMediaMasculino,
      },
    ];

    // Configuración del carrusel
    const carouselSettings = {
      dots: false, // Ocultar puntos
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      nextArrow: (
        <div
          style={{
            position: "absolute",
            right: "-30px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            zIndex: 2,
          }}
        >
          <ArrowForwardIosIcon style={{ fontSize: "2rem", color: "#000" }} />
        </div>
      ),
      prevArrow: (
        <div
          style={{
            position: "absolute",
            left: "-30px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            zIndex: 2,
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: "2rem", color: "#000" }} />
        </div>
      ),
      responsive: [
        {
          breakpoint: 960,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 600,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
      ],
    };

    // Inputs para los pliegues cutáneos
    const inputs = [
      { label: "Pectoral (mm)", name: "pectoral" },
      { label: "Tricipital (mm)", name: "tricipital" },
      { label: "Bicipital (mm)", name: "bicipital" },
      { label: "Subescapular (mm)", name: "subescapular" },
      { label: "Midaxilar (mm)", name: "midaxilar" },
      { label: "Suprailiaco (mm)", name: "suprailiaco" },
      { label: "Abdominal (mm)", name: "abdominal" },
      { label: "Muslo Medio (mm)", name: "musloMedio" },
      { label: "Pantorrilla media (mm)", name: "pantorrillaMedia" },
    ];

    return (
      <Box sx={{ padding: 3 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          Pliegues Cutáneos
        </Typography>

        {/* Carrusel */}
        <Box
          sx={{ marginBottom: 3, position: "relative", overflow: "visible" }}
        >
          <Slider {...carouselSettings}>
            {carouselImages.map((image, index) => (
              <div
                key={index}
                style={{ padding: "10px", position: "relative" }}
                onClick={() => handleImageClick(image.src)}
              >
                <Box
                  sx={{
                    position: "relative",
                    margin: "0 auto",
                    width: "90px",
                    height: "90px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    cursor: "pointer",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                    "&:hover": {
                      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.5)",
                    },
                  }}
                >
                  <img
                    src={image.src}
                    alt={image.label}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    draggable="false"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#fff",
                      zIndex: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <VisibilityIcon fontSize="medium" />
                  </Box>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    marginTop: 1,
                    fontWeight: "bold",
                    color: "text.secondary",
                  }}
                >
                  {image.label}
                </Typography>
              </div>
            ))}
          </Slider>
        </Box>

        {/* Dialog para el zoom */}
        <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md">
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
            }}
          >
            <img
              src={currentImage}
              alt="Zoom"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </DialogContent>
        </Dialog>

        {/* Inputs */}
        <Grid container spacing={2}>
          {/* Fecha */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha"
                variant="outlined"
                name="fechaPliegues"
                value={formValues.fechaPliegues || ""}
                onChange={handleInputChange}
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: today,
                }}
                sx={{
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    cursor: "pointer",
                  },
                }}
              />
            </Grid>
          </Grid>
          {inputs.map((input, index) => (
            <Grid item xs={12} md={6} key={index}>
              <TextField
                label={input.label}
                variant="outlined"
                name={input.name}
                value={formValues[input.name] || ""}
                onChange={handleInputChange}
                type="number"
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
);

export default PlieguesCutaneos;
