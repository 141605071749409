// src/services/patientService.js

import axios from "axios";

const BACKEND_URL = "/api/paciente";

/**
 * Función para crear un nuevo paciente en el backend
 * @param {Object} datosPaciente - Datos recopilados del formulario
 * @param {Array} patologiasList - Lista completa de patologías para obtener nombres
 * @returns {Promise<Object>} - Respuesta del backend
 */
export const crearPaciente = async (datosPaciente, patologiasList) => {
  try {
    const {
      dataNutriologoSubdominio,
      formValuesBienvenida,
      formValuesBasic,
      formValuesSalud,
      formValuesComposicion,
      formValuesGlucosa,
      formValuesHablameDeTi,
    } = datosPaciente;

    // 1. Cálculo del IMC
    const estaturaMetros = formValuesBasic.estatura / 100; // Convertir cm a metros
    const imc = parseFloat(
      (
        parseFloat(formValuesBasic.pesoPaciente) /
        (estaturaMetros * estaturaMetros)
      ).toFixed(2)
    );

    // 2. Cálculo del Peso Ideal usando la Fórmula de Van der Vael
    let pesoIdeal;
    if (formValuesBasic.sexo.toLowerCase() === "m") {
      // Asumiendo 'M' para masculino
      pesoIdeal = (formValuesBasic.estatura - 150) * 0.75 + 50;
    } else {
      // Para femenino u otros
      pesoIdeal = (formValuesBasic.estatura - 150) * 0.6 + 50;
    }
    pesoIdeal = parseFloat(pesoIdeal.toFixed(2));

    // 3. Fecha de registro en formato 'YYYY-MM-DD HH:mm:ss'
    const fechaRegistro = new Date();
    const pad = (n) => String(n).padStart(2, "0");
    const fechaRegistroFormateada = `${fechaRegistro.getFullYear()}-${pad(
      fechaRegistro.getMonth() + 1
    )}-${pad(fechaRegistro.getDate())} ${pad(fechaRegistro.getHours())}:${pad(
      fechaRegistro.getMinutes()
    )}:${pad(fechaRegistro.getSeconds())}`;

    // 4. Preparar las patologías seleccionadas
    const patologiasPadecidas = Object.keys(formValuesSalud.patologias)
      .filter((clave) => formValuesSalud.patologias[clave])
      .map((clave) => {
        const patologia = patologiasList.find((p) => p.clave === clave);
        return patologia
          ? { nombre: patologia.nombre, clave: patologia.clave }
          : { nombre: clave, clave };
      });

    // 5. Preparar los grupos de alergias seleccionados
    const gruposAlergias = Object.keys(formValuesSalud.gruposAlergias).filter(
      (clave) => formValuesSalud.gruposAlergias[clave]
    );

    // 6. Preparar los contenidos de alergias seleccionados
    const contenidoAlergia = Object.keys(formValuesSalud.contenidos).filter(
      (clave) => formValuesSalud.contenidos[clave]
    );

    // 7. Estructurar la composición corporal
    const composicionCorporal = {
      biometriaHematica: {
        fecha:
          formValuesComposicion.biometriaHematicaFecha ||
          fechaRegistro.toISOString(),
        hemoglobina: {
          cantidad: formValuesComposicion.hemoglobina || "",
          medida: formValuesComposicion.hemoglobinaUnidad, // Puedes ajustar esto según tu formulario
        },
        // Agrega otros campos si es necesario
      },
      perfilLipidico: {
        fecha:
          formValuesComposicion.perfilLipidicoFecha ||
          fechaRegistro.toISOString(),
        trigliceridos: {
          cantidad: formValuesComposicion.trigliceridos || "",
          medida: formValuesComposicion.trigliceridosMedida || "",
        },
        colesterolTotal: {
          cantidad: formValuesComposicion.colesterolTotal || "",
          medida: formValuesComposicion.colesterolTotalMedida || "",
        },
        colesterolLDL: {
          cantidad: formValuesComposicion.colesterolLDL || "",
          medida: formValuesComposicion.colesterolLDLMedida || "",
        },
        colesterolHDL: {
          cantidad: formValuesComposicion.colesterolHDL || "",
          medida: formValuesComposicion.colesterolHDLMedida || "",
        },
        colesterolVLDL: {
          cantidad: formValuesComposicion.colesterolVLDL || "",
          medida: formValuesComposicion.colesterolVLDLMedida || "",
        },
      },

      quimicaSanguinea: {
        fecha:
          formValuesComposicion.quimicaSanguineaFecha ||
          fechaRegistro.toISOString(),
        glucosa: {
          cantidad: formValuesComposicion.glucosa || "",
          medida: formValuesComposicion.glucosaMedida || "",
        },
        creatinina: {
          cantidad: formValuesComposicion.creatinina || "",
          medida: formValuesComposicion.creatininaMedida || "",
        },
        albumina: {
          cantidad: formValuesComposicion.albumina || "",
          medida: formValuesComposicion.albuminaMedida || "",
        },
        urea: {
          cantidad: formValuesComposicion.urea || "",
          medida: formValuesComposicion.ureaMedida || "",
        },
        acidoUrico: {
          cantidad: formValuesComposicion.acidoUrico || "",
          medida: formValuesComposicion.acidoUricoMedida || "",
        },
        hierro: {
          cantidad: formValuesComposicion.hierro || "",
          medida: formValuesComposicion.hierroMedida || "",
        },
        glucosaPlasma: {
          cantidad: formValuesComposicion.glucosaPlasma || "",
          medida: formValuesComposicion.glucosaPlasmaMedida || "",
        },
      },

      pruebasDeGlucosa: {
        glucosaCualquierHora: {
          fecha:
            formValuesGlucosa.glucosaCualquierHoraFecha ||
            fechaRegistro.toISOString(),
          cantidad: formValuesGlucosa.glucosaCualquierHora || "",
          medida: formValuesGlucosa.glucosaCualquierHoraMedida || "",
        },
        glucosaAyuno: {
          fecha:
            formValuesGlucosa.glucosaAyunoFecha || fechaRegistro.toISOString(),
          cantidad: formValuesGlucosa.glucosaAyuno || "",
          medida: formValuesGlucosa.glucosaAyunoMedida || "",
        },
        hemoglobinaGlucosilada: {
          fecha:
            formValuesGlucosa.hemoglobinaGlucosiladaFecha ||
            fechaRegistro.toISOString(),
          cantidad: formValuesGlucosa.hemoglobinaGlucosilada || "",
          medida: formValuesGlucosa.hemoglobinaGlucosiladaMedida || "",
        },
        toleranciaOralGlucosa: {
          fecha:
            formValuesGlucosa.toleranciaGlucosaFecha ||
            fechaRegistro.toISOString(),
          cantidad: formValuesGlucosa.toleranciaGlucosa || "",
          medida: formValuesGlucosa.toleranciaGlucosaMedida || "",
        },
      },

      examenGeneralOrina: {
        fecha:
          formValuesComposicion.examenGeneralOrinaFecha ||
          fechaRegistro.toISOString(),
        glucosaOrina: {
          cantidad: formValuesComposicion.glucosaOrina || "",
          medida: formValuesComposicion.glucosaOrinaMedida || "",
        },
        proteinasOrina: {
          cantidad: formValuesComposicion.proteinasOrina || "",
          medida: formValuesComposicion.proteinasOrinaMedida || "",
        },
        hemoglobinaOrina: {
          cantidad: formValuesComposicion.hemoglobinaOrina || "",
          medida: formValuesComposicion.hemoglobinaOrinaMedida || "",
        },
        cuerposCetonicos: {
          cantidad: formValuesComposicion.cuerposCetonicos || "",
          medida: formValuesComposicion.cuerposCetonicosMedida || "",
        },
        pigmentosBiliares: {
          cantidad: formValuesComposicion.pigmentosBiliares || "",
          medida: formValuesComposicion.pigmentosBiliaresMedida || "",
        },
        urobilinogeno: {
          cantidad: formValuesComposicion.urobilinogeno || "",
          medida: formValuesComposicion.urobilinogenoMedida || "",
        },
        nitritos: {
          cantidad: formValuesComposicion.nitritos || "",
          medida: formValuesComposicion.nitritosMedida || "",
        },
      },

      indicesCorporales: {
        fecha:
          formValuesComposicion.indicesCorporalesFecha ||
          fechaRegistro.toISOString(),
        circunferenciaCintura: {
          cantidad: formValuesComposicion.circunferenciaCintura || "",
          medida: formValuesComposicion.circunferenciaCinturaMedida || "",
        },
        porcentajeGrasa: {
          cantidad: formValuesComposicion.porcentajeGrasa || "",
          medida: formValuesComposicion.porcentajeGrasaMedida || "",
        },
        porcentajeAgua: {
          cantidad: formValuesComposicion.porcentajeAgua || "",
          medida: formValuesComposicion.porcentajeAguaMedida || "",
        },
        porcentajeMusculo: {
          cantidad: formValuesComposicion.porcentajeMusculo || "",
          medida: formValuesComposicion.porcentajeMusculoMedida || "",
        },
        edadDigestion: {
          cantidad: formValuesComposicion.edadDigestion || "",
          medida: formValuesComposicion.edadDigestionMedida || "",
        },
        grasaVisceral: {
          cantidad: formValuesComposicion.grasaVisceral || "",
          medida: formValuesComposicion.grasaVisceralMedida || "",
        },
      },

      antropometria: {
        fecha:
          formValuesComposicion.antropometriaFecha ||
          fechaRegistro.toISOString(),
        brazo: {
          cantidad: formValuesComposicion.brazo || "",
          medida: formValuesComposicion.brazoMedida || "",
        },
        hombros: {
          cantidad: formValuesComposicion.hombros || "",
          medida: formValuesComposicion.hombrosMedida || "",
        },
        pectoralBusto: {
          cantidad: formValuesComposicion.pectoralBusto || "",
          medida: formValuesComposicion.pectoralBustoMedida || "",
        },
        abdomenCintura: {
          cantidad: formValuesComposicion.abdomenCintura || "",
          medida: formValuesComposicion.abdomenCinturaMedida || "",
        },
        caderaGluteos: {
          cantidad: formValuesComposicion.caderaGluteos || "",
          medida: formValuesComposicion.caderaGluteosMedida || "",
        },
        muslo: {
          cantidad: formValuesComposicion.muslo || "",
          medida: formValuesComposicion.musloMedida || "",
        },
        pantorrilla: {
          cantidad: formValuesComposicion.pantorrilla || "",
          medida: formValuesComposicion.pantorrillaMedida || "",
        },
      },

      plieguesCutaneos: {
        fecha:
          formValuesComposicion.plieguesCutaneosFecha ||
          fechaRegistro.toISOString(),
        pectoral: {
          cantidad: formValuesComposicion.pectoral || "",
          medida: formValuesComposicion.pectoralMedida || "",
        },
        tricipital: {
          cantidad: formValuesComposicion.tricipital || "",
          medida: formValuesComposicion.tricipitalMedida || "",
        },
        bicipital: {
          cantidad: formValuesComposicion.bicipital || "",
          medida: formValuesComposicion.bicipitalMedida || "",
        },
        subescapular: {
          cantidad: formValuesComposicion.subescapular || "",
          medida: formValuesComposicion.subescapularMedida || "",
        },
        midaxilar: {
          cantidad: formValuesComposicion.midaxilar || "",
          medida: formValuesComposicion.midaxilarMedida || "",
        },
        suprailiaco: {
          cantidad: formValuesComposicion.suprailiaco || "",
          medida: formValuesComposicion.suprailiacoMedida || "",
        },
        abdominal: {
          cantidad: formValuesComposicion.abdominal || "",
          medida: formValuesComposicion.abdominalMedida || "",
        },
        musloMedio: {
          cantidad: formValuesComposicion.musloMedio || "",
          medida: formValuesComposicion.musloMedioMedida || "",
        },
        pantorrillaMedia: {
          cantidad: formValuesComposicion.pantorrillaMedia || "",
          medida: formValuesComposicion.pantorrillaMediaMedida || "",
        },
      },
    };

    // 8. Construir el JSON completo
    const pacienteData = {
      nutriologoId: dataNutriologoSubdominio._id,
      correo: formValuesBienvenida.email,
      password: formValuesBienvenida.password,
      nombrePaciente: formValuesBasic.nombrePaciente,
      apellidoPaciente: formValuesBasic.apellidoPaciente,
      imc: imc,
      edad: parseInt(formValuesBasic.edad, 10),
      telefono: formValuesBasic.telefono,
      pesoPaciente: formValuesBasic.pesoPaciente,
      fechaNacimiento: formValuesBasic.fechaNacimiento,
      sexo: formValuesBasic.sexo,
      embarazoActivo: formValuesBasic.embarazoActivo,
      tiempoEmbarazo: formValuesBasic.tiempoEmbarazo,
      estatura: formValuesBasic.estatura,
      pesoIdeal: pesoIdeal,
      fechaRegistro: fechaRegistroFormateada,
      ocupacionTexto: formValuesHablameDeTi.ocupacionTexto,
      preguntaEjercicio: formValuesHablameDeTi.preguntaEjercicio,
      diasPorSemanaEjercicio: formValuesHablameDeTi.diasPorSemanaEjercicio,
      horasPorDiaEjercicio: formValuesHablameDeTi.horasPorDiaEjercicio,
      alimentacionCotidiana: formValuesHablameDeTi.alimentacionCotidiana,
      objetivosTexto: formValuesHablameDeTi.objetivosTexto,
      patologiasPadecidas: patologiasPadecidas,
      gruposAlergias: gruposAlergias,
      contenidoAlergia: contenidoAlergia.map((nombre) => nombre), // Asume que el ID es el nombre
      composicionCorporal: composicionCorporal,
      actividadDiaria: "",
      anotacionesDelPaciente: ["0"],
      cantidadEjercicio: "",
      fechaProximaCita: "",
      fechaUltimaCita: "2024-12-11T16:02:34.647Z",
      minutosPorDiaEjercicio: 0,
      totalTiempoDiaEjercicio: 0,
    };

    // 9. Realizar la solicitud POST al backend
    const response = await axios.post(BACKEND_URL, pacienteData);

    return response.data; // O lo que tu backend retorne
  } catch (error) {
    console.error("Error al crear el paciente:", error);
    throw error;
  }
};
