import React, { forwardRef } from "react";
import { Typography, Fade, Box, Avatar, TextField } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const Bienvenida = forwardRef((props, ref) => {
  const { formValues, onChange } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
          p: { xs: 3, sm: 5, md: 8 },
          background: "linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%)",
          borderRadius: 4,
          boxShadow: 10,
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Elementos Decorativos Cuadrados */}
        <Box
          sx={{
            position: "absolute",
            top: -30,
            right: -30,
            width: 80,
            height: 80,
            backgroundColor: "primary.light",
            borderRadius: 2, // Bordes ligeramente redondeados
            opacity: 0.2,
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: -30,
            left: -30,
            width: 100,
            height: 100,
            backgroundColor: "secondary.light",
            borderRadius: 2, // Bordes ligeramente redondeados
            opacity: 0.2,
            zIndex: 1,
          }}
        />

        {/* Avatar con Icono de Perfil */}
        <Avatar
          sx={{
            width: { xs: 80, sm: 100 },
            height: { xs: 80, sm: 100 },
            mb: 4,
            bgcolor: "primary.main",
            boxShadow: 6,
            transition: "transform 0.3s, box-shadow 0.3s",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 12,
            },
          }}
        >
          <PersonIcon sx={{ fontSize: { xs: 60, sm: 80 }, color: "#fff" }} />
        </Avatar>

        {/* Título Principal */}
        <Typography
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: "bold",
            fontSize: "26px",
            color: "primary.dark",
            animation: "fadeInUp 1s ease-in-out",
          }}
        >
          ¡Hola y Bienvenido!
        </Typography>

        {/* Descripción */}
        <Typography
          variant="body1"
          color="text.secondary"
          gutterBottom
          sx={{ maxWidth: 700, mb: 4 }}
        >
          Mi nombre es:
          <Typography variant="h5" color="primary.main">
            {props.dataNutriologoSubdominio?.usuario.nombre}{" "}
            {props.dataNutriologoSubdominio?.usuario.apellidos}
          </Typography>
          <br></br>
          Es un gusto que me hayas elegido para ayudarte en este ciclo tan
          importante de tu vida, por favor, proporcioname los siguientes datos
          para personalizar tu tratamiento y darte las mejores recomendaciones.
        </Typography>

        {/* Texto Adicional */}
        <Typography variant="h6" color="primary.main" sx={{ mb: 4 }}>
          ¡Juntos vamos a lograr todos tus objetivos!
        </Typography>

        {/* Instrucciones */}
        <Box
          sx={{
            textAlign: "left",
            maxWidth: 700,
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            padding: { xs: 3, sm: 4 },
            borderRadius: 3,
            boxShadow: 4,
            mb: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "medium", color: "text.primary", mb: 2 }}
          >
            Instrucciones:
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="ul"
            sx={{ pl: 4 }}
          >
            <li>Completa tus datos personales.</li>
            <li>Dime cualquier información extra que tengas sobre ti.</li>
            <li>Cuentame tu dia a dia.</li>
          </Typography>

          {/* Input de correo electrónico */}
          <TextField
            label="Correo Electrónico"
            name="email"
            value={formValues.email || ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
          />

          {/* Input de contraseña */}
          <TextField
            label="Contraseña"
            name="password"
            type="password"
            value={formValues.password || ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
          />

          {/* Input de confirmación de contraseña */}
          <TextField
            label="Confirmar Contraseña"
            name="confirmPassword"
            type="password"
            value={formValues.confirmPassword || ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
          />
        </Box>

        {/* Título Final */}
        <Typography
          variant="h4"
          sx={{
            mb: 2,
            fontWeight: "bold",
            color: "primary.dark",
            animation: "fadeInUp 1.5s ease-in-out",
          }}
        >
          ¡Empecemos!
        </Typography>

        {/* Animación Personalizada */}
        <style jsx>{`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          li {
            margin-bottom: 8px;
          }
        `}</style>
      </Box>
    </Fade>
  );
});

export default Bienvenida;
