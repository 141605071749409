// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PantallaRegistroPacienteOnline from "./components/crearPaciente/PantallaRegistroPacienteOnline";
import Bienvenida from "./components/welcomePage/WelcomePage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PantallaRegistroPacienteOnline />} />
        <Route path="/welcomePage" element={<Bienvenida />} />
        {/* Puedes agregar más rutas aquí si es necesario */}
      </Routes>
    </Router>
  );
}

export default App;
