// src/components/crearPaciente/constants.js

// Definir patologías ordenadas alfabéticamente
export const patologias = [
  { clave: "anemia_leve", nombre: "Anemia Leve" },
  { clave: "anemia_moderada", nombre: "Anemia Moderada" },
  { clave: "anemia_severa", nombre: "Anemia Severa" },
  { clave: "ANEMIA", nombre: "Anemia" },
  { clave: "cardiopatia", nombre: "Cardiopatía" },
  { clave: "colitis", nombre: "Colitis" },
  { clave: "colitis_crohn", nombre: "Colitis de Crohn" },
  { clave: "colitis_ulcerosa", nombre: "Colitis Ulcerosa" },
  { clave: "desnutricion", nombre: "Desnutrición" },
  { clave: "desnutricion_cronica", nombre: "Desnutrición Crónica" },
  { clave: "desnutricion_leve", nombre: "Desnutrición Leve" },
  { clave: "desnutricion_moderada", nombre: "Desnutrición Moderada" },
  { clave: "desnutricion_severa", nombre: "Desnutrición Severa" },
  { clave: "diabetes_gestacional", nombre: "Diabetes Gestacional" },
  { clave: "diabetes_tipo_1", nombre: "Diabetes Tipo 1" },
  { clave: "diabetes_tipo_2", nombre: "Diabetes Tipo 2" },
  { clave: "dislipidemia", nombre: "Dislipidemia" },
  { clave: "dislipidemia_aterogenica", nombre: "Dislipidemia Aterogénica" },
  { clave: "dislipidemia_mixta", nombre: "Dislipidemia Mixta" },
  {
    clave: "dislipidemia_no_aterogenica",
    nombre: "Dislipidemia No Aterogénica",
  },
  { clave: "enfermedad_celiaca", nombre: "Enfermedad Celíaca" },
  { clave: "esteatosis_hepatica", nombre: "Esteatosis Hepática" },
  { clave: "esteatosis_no_alcoholica", nombre: "Esteatosis No Alcohólica" },
  { clave: "gastritis", nombre: "Gastritis" },
  { clave: "higado_graso", nombre: "Hígado Graso" },
  {
    clave: "hiperalfalipoproteinemia_aislada",
    nombre: "Hiperalfalipoproteinemia Aislada",
  },
  { clave: "hipercolesterolemia", nombre: "Hipercolesterolemia" },
  { clave: "hipertencion", nombre: "Hipertensión" },
  { clave: "hipertrigliceridemia", nombre: "Hipertrigliceridemia" },
  { clave: "hipotiroidismo", nombre: "Hipotiroidismo" },
  { clave: "insuficiencia_renal", nombre: "Insuficiencia Renal" },
  { clave: "OBESIDAD_GRADO_1", nombre: "Obesidad Grado 1" },
  { clave: "OBESIDAD_GRADO_2", nombre: "Obesidad Grado 2" },
  { clave: "OBESIDAD_GRADO_3", nombre: "Obesidad Grado 3" },
  { clave: "osteoporosis", nombre: "Osteoporosis" },
  { clave: "pre_eclampsia", nombre: "Pre-eclampsia" },
  { clave: "sindrome_down", nombre: "Síndrome de Down" },
  { clave: "sx_metabolico", nombre: "Síndrome Metabólico" },
  { clave: "sobrepeso", nombre: "Sobrepeso" },
];

// Definir grupos de alergias ordenados alfabéticamente
export const gruposAlergiasList = [
  { clave: "grupoCarneRoja", nombre: "Carne Roja" },
  { clave: "intoleranciaFructosa", nombre: "Intolerancia a la fructosa" },
  { clave: "intoleranciaGluten", nombre: "Intolerancia al gluten" },
  { clave: "intoleranciaLactosa", nombre: "Intolerancia a la lactosa" },
  { clave: "grupoEspecias", nombre: "Especias" },
  { clave: "grupoHongos", nombre: "Hongos" },
  { clave: "grupoLegumbres", nombre: "Legumbres" },
  { clave: "grupoMariscos", nombre: "Mariscos" },
  { clave: "grupoSemillas", nombre: "Semillas" },
];
