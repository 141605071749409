// src/theme/theme.js

import { createTheme } from "@mui/material/styles";

// Crear el tema personalizado utilizando directamente los valores de color
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Azul
    },
    secondary: {
      main: "#dc004e", // Rojo
    },
    background: {
      default: "#f5f5f5", // Gris claro
    },
    error: {
      main: "#d32f2f", // Rojo oscuro
    },
    success: {
      main: "#388e3c", // Verde
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    h5: {
      fontWeight: 700,
    },
    body1: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#bdbdbd",
          "&.Mui-active": {
            color: "#1976d2", // Azul
          },
          "&.Mui-completed": {
            color: "#1976d2", // Azul
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
  },
});

export default theme;
