import React, { useState, forwardRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Dialog,
  DialogContent,
} from "@mui/material";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Importar imágenes para ambos géneros
import BrazosMasculino from "../../assets/images/antropometria/brazos.png";
import BrazosFemenino from "../../assets/images/antropometria/brazosMujer.png";
import HombrosMasculino from "../../assets/images/antropometria/hombros.png";
import HombrosFemenino from "../../assets/images/antropometria/hombrosMujer.png";
import PectoralBustoMasculino from "../../assets/images/antropometria/pectoralBusto.png";
import PectoralBustoFemenino from "../../assets/images/antropometria/pectoralBustoMujer.png";
import AbdomenCinturaMasculino from "../../assets/images/antropometria/abdomenCintura.png";
import AbdomenCinturaFemenino from "../../assets/images/antropometria/abdomenCinturaMujer.png";
import CaderaGluteosMasculino from "../../assets/images/antropometria/caderaGluteos.png";
import CaderaGluteosFemenino from "../../assets/images/antropometria/caderaGluteosMujer.png";
import MusloMasculino from "../../assets/images/antropometria/muslo.png";
import MusloFemenino from "../../assets/images/antropometria/musloMujer.png";
import PantorrillaMasculino from "../../assets/images/antropometria/pantorrilla.png";
import PantorrillaFemenino from "../../assets/images/antropometria/pantorrillaMujer.png";

const Antropometria = forwardRef(
  ({ formValues, formValuesBasic, onChange }, ref) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      onChange(name, value);
    };

    const today = new Date().toISOString().split("T")[0]; // Fecha actual para evitar fechas futuras

    // Abrir el Dialog con la imagen seleccionada
    const handleImageClick = (image) => {
      setCurrentImage(image);
      setOpenDialog(true);
    };

    // Cerrar el Dialog
    const handleDialogClose = () => {
      setOpenDialog(false);
    };

    // Imágenes según el género
    const carouselImages = [
      {
        label: "Brazos",
        src:
          formValuesBasic?.sexo === "femenino"
            ? BrazosMasculino
            : BrazosFemenino,
      },
      {
        label: "Hombros",
        src:
          formValuesBasic?.sexo === "femenino"
            ? HombrosMasculino
            : HombrosFemenino,
      },
      {
        label: "Pectoral/Busto",
        src:
          formValuesBasic?.sexo === "femenino"
            ? PectoralBustoMasculino
            : PectoralBustoFemenino,
      },
      {
        label: "Abdomen/Cintura",
        src:
          formValuesBasic?.sexo === "femenino"
            ? AbdomenCinturaMasculino
            : AbdomenCinturaFemenino,
      },
      {
        label: "Cadera/Gluteos",
        src:
          formValuesBasic?.sexo === "femenino"
            ? CaderaGluteosMasculino
            : CaderaGluteosFemenino,
      },
      {
        label: "Muslo",
        src:
          formValuesBasic?.sexo === "femenino" ? MusloMasculino : MusloFemenino,
      },
      {
        label: "Pantorrilla",
        src:
          formValuesBasic?.sexo === "femenino"
            ? PantorrillaMasculino
            : PantorrillaFemenino,
      },
    ];

    // Configuración del carrusel
    const carouselSettings = {
      dots: false, // Ocultar puntos
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      nextArrow: (
        <div
          style={{
            position: "absolute",
            right: "-30px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            zIndex: 2,
          }}
        >
          <ArrowForwardIosIcon style={{ fontSize: "2rem", color: "#000" }} />
        </div>
      ),
      prevArrow: (
        <div
          style={{
            position: "absolute",
            left: "-30px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            zIndex: 2,
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: "2rem", color: "#000" }} />
        </div>
      ),
      responsive: [
        {
          breakpoint: 960,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 600,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
      ],
    };

    // Inputs para antropometria
    const inputs = [
      { label: "Brazos (cm)", name: "brazos" },
      { label: "Hombros (cm)", name: "hombros" },
      { label: "Pectoral/Busto (cm)", name: "pectoralBusto" },
      { label: "Abdomen/Cintura (cm)", name: "abdomenCintura" },
      { label: "Cadera/Gluteos (cm)", name: "caderaGluteos" },
      { label: "Muslo (cm)", name: "muslo" },
      { label: "Pantorrilla (cm)", name: "pantorrilla" },
    ];

    return (
      <Box sx={{ padding: 3 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          Perimetros corporales
        </Typography>

        {/* Carrusel */}
        <Box
          sx={{ marginBottom: 3, position: "relative", overflow: "visible" }}
        >
          <Slider {...carouselSettings}>
            {carouselImages.map((image, index) => (
              <div
                key={index}
                style={{ padding: "10px", position: "relative" }}
                onClick={() => handleImageClick(image.src)}
              >
                <Box
                  sx={{
                    position: "relative",
                    margin: "0 auto",
                    width: "90px",
                    height: "90px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    cursor: "pointer",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                    "&:hover": {
                      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.5)",
                    },
                  }}
                >
                  <img
                    src={image.src}
                    alt={image.label}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    draggable="false"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#fff",
                      zIndex: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <VisibilityIcon fontSize="medium" />
                  </Box>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    marginTop: 1,
                    fontWeight: "bold",
                    color: "text.secondary",
                  }}
                >
                  {image.label}
                </Typography>
              </div>
            ))}
          </Slider>
        </Box>

        {/* Dialog para el zoom */}
        <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md">
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
            }}
          >
            <img
              src={currentImage}
              alt="Zoom"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </DialogContent>
        </Dialog>

        {/* Inputs */}
        <Grid container spacing={2}>
          {/* Fecha */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha"
                variant="outlined"
                name="fechaAntropometria"
                value={formValues.fechaAntropometria || ""}
                onChange={handleInputChange}
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: today,
                }}
                sx={{
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    cursor: "pointer",
                  },
                }}
              />
            </Grid>
          </Grid>
          {inputs.map((input, index) => (
            <Grid item xs={12} md={6} key={index}>
              <TextField
                label={input.label}
                variant="outlined"
                name={input.name}
                value={formValues[input.name] || ""}
                onChange={handleInputChange}
                type="number"
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
);

export default Antropometria;
