import React, { forwardRef } from "react";
import { Box, Typography, Grid, TextField } from "@mui/material";

const IndicesCorporales = forwardRef(({ formValues, onChange }, ref) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const today = new Date().toISOString().split("T")[0]; // Fecha actual para evitar fechas futuras

  const inputs = [
    { label: "Porcentaje de grasa (%)", name: "porcentajeGrasa" },
    { label: "Porcentaje de agua (%)", name: "porcentajeAgua" },
    { label: "Porcentaje de músculo (%)", name: "porcentajeMusculo" },
    { label: "DCI (Edad de digestión)", name: "edadDigestion" },
    { label: "VISC (Grasa visceral)", name: "grasaVisceral" },
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "primary.main" }}
      >
        Índices Corporales
      </Typography>

      {/* Inputs */}
      <Grid container spacing={2}>
        {/* Fecha */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Fecha"
              variant="outlined"
              name="fechaIndices"
              value={formValues.fechaIndices || ""}
              onChange={handleInputChange}
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: today,
              }}
              sx={{
                "& input[type='date']::-webkit-calendar-picker-indicator": {
                  cursor: "pointer",
                },
              }}
            />
          </Grid>
        </Grid>
        {inputs.map((input, index) => (
          <Grid item xs={12} md={6} key={index}>
            <TextField
              label={input.label}
              variant="outlined"
              name={input.name}
              value={formValues[input.name] || ""}
              onChange={handleInputChange}
              type="number"
              fullWidth
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default IndicesCorporales;
