// src/components/crearPaciente/Bienvenida.js

import React from "react";
import {
  Typography,
  Box,
  CardContent,
  Button,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CheckCircle as CheckCircleIcon,
  Favorite as FavoriteIcon,
  LocalHospital as LocalHospitalIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";

// Contenedor de Fondo con Overlay
const BackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundImage:
    "url('https://images.unsplash.com/photo-1587502536263-3ff76d1ef9c4?ixlib=rb-4.0.3&auto=format&fit=crop&w=1950&q=80')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
}));

// Tarjeta Estilizada con Animación
const StyledCard = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[10],
  maxWidth: 600, // Ajustado para una mejor visualización en columna
  width: "100%",
  padding: theme.spacing(4),
  position: "relative",
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
  },
}));

// Caja de Características Rediseñada
const FeatureCard = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2), // Reducido de theme.spacing(3) a theme.spacing(2)
  marginBottom: theme.spacing(2), // Reducido de theme.spacing(3) a theme.spacing(2)
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

// Icono en el Centro
const FeatureIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1), // Reducido de theme.spacing(2) a theme.spacing(1)
  "& svg": {
    fontSize: 40, // Reducido de 50 a 40
  },
}));

const WelcomePage = () => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BackgroundContainer>
      <StyledCard
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        role="main"
        aria-labelledby="welcome-title"
      >
        <CardContent>
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              color="primary"
              sx={{ fontSize: 80, mb: 2 }}
              aria-hidden="true"
            />
            <Typography
              id="welcome-title"
              variant={isSmDown ? "h5" : "h4"}
              sx={{ fontWeight: "bold", mb: 1, color: "text.primary" }}
            >
              ¡Bienvenido!
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ mb: 3 }}
            >
              Tu viaje hacia una vida más saludable ha comenzado.
            </Typography>
            {/* Sección de Descripción */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 2, color: "text.primary" }}
              >
                Tu plan alimenticio ya está en proceso...
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Te estaré avisando cuando tu plan alimenticio esté listo en un
                lapso no mayor a 24 horas.
              </Typography>
            </Box>

            {/* Características */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <FeatureCard
                role="button"
                tabIndex={0}
                aria-labelledby="consulta-dieta-title"
              >
                <FeatureIcon>
                  <FavoriteIcon />
                </FeatureIcon>
                <Typography
                  id="consulta-dieta-title"
                  variant="subtitle1" // Cambiado de "h6" a "subtitle1"
                  sx={{ fontWeight: "bold", mb: 1, color: "text.primary" }}
                >
                  Consulta tu dieta
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.9rem" }} // Reducido el tamaño de fuente
                >
                  Aquí podrás ver la dieta que te fue asignada.
                </Typography>
              </FeatureCard>

              <FeatureCard
                role="button"
                tabIndex={0}
                aria-labelledby="consultas-online-title"
              >
                <FeatureIcon>
                  <LocalHospitalIcon />
                </FeatureIcon>
                <Typography
                  id="consultas-online-title"
                  variant="subtitle1" // Cambiado de "h6" a "subtitle1"
                  sx={{ fontWeight: "bold", mb: 1, color: "text.primary" }}
                >
                  Consultas Online
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.9rem" }} // Reducido el tamaño de fuente
                >
                  Realiza tus consultas desde la comodidad de tu hogar.
                </Typography>
              </FeatureCard>
              {/* Puedes añadir más características aquí si lo deseas */}
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              href="/perfil"
              sx={{
                mt: 2,
                paddingX: 4,
                paddingY: 1.5,
                fontSize: "1rem",
                borderRadius: 3,
                textTransform: "none",
              }}
              aria-label="Visitar mi perfil"
            >
              Visitar mi perfil
            </Button>
          </Box>
        </CardContent>
      </StyledCard>
    </BackgroundContainer>
  );
};

export default WelcomePage;
