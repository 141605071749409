// src/components/Paso1InformacionBasica.js

import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  IconButton,
  FormHelperText,
  Fade,
  Box,
} from "@mui/material"; // Actualizado a @mui/material
import CloseIcon from "@mui/icons-material/Close"; // Actualizado a @mui/icons-material

import AccountCircle from "@mui/icons-material/AccountCircle";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Cake from "@mui/icons-material/Cake";
import Phone from "@mui/icons-material/Phone";
import Email from "@mui/icons-material/Email";
import FitnessCenter from "@mui/icons-material/FitnessCenter";
import Straighten from "@mui/icons-material/Straighten";
import CalendarToday from "@mui/icons-material/CalendarToday";
import AccessTime from "@mui/icons-material/AccessTime";

const allowOnlyLetters = (e) => {
  const regex = /^[A-Za-zÀ-ú\s]$/;
  if (!regex.test(e.key)) {
    e.preventDefault();
  }
};

const allowOnlyNumbers = (e) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

const allowNumbersAndDot = (e) => {
  if (!/[0-9.]/.test(e.key)) {
    e.preventDefault();
  }
};

const today = new Date();
const yyyy = today.getFullYear();
const mm = String(today.getMonth() + 1).padStart(2, "0");
const dd = String(today.getDate()).padStart(2, "0");
const maxDate = `${yyyy}-${mm}-${dd}`;

const Paso1InformacionBasica = forwardRef(
  ({ formValues, onChange, formValuesEmail }, ref) => {
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [firstValidationError, setFirstValidationError] = useState("");

    const handleCloseError = () => {
      setOpenError(false);
    };

    const handleFieldChange = (name, value) => {
      onChange(name, value);
      if (firstValidationError === name) {
        setFirstValidationError("");
      }
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      handleFieldChange(name, value);
    };

    const validateField = (fieldName, value) => {
      const soloLetras = /^[A-Za-zÀ-ú\s]+$/;
      const hoy = new Date();

      switch (fieldName) {
        case "nombrePaciente":
          if (!value) return "Falta el campo Nombre(s)";
          if (!soloLetras.test(value) || value.length > 50) {
            return "El campo Nombre(s) es inválido. Solo letras y máximo 50 caracteres.";
          }
          break;

        case "apellidoPaciente":
          if (!value) return "Falta el campo Apellidos";
          if (!soloLetras.test(value) || value.length > 50) {
            return "El campo Apellidos es inválido. Solo letras y máximo 50 caracteres.";
          }
          break;

        case "edad":
          if (!value) return "Falta el campo Edad";
          if (!/^[0-9]{1,2}$/.test(value)) {
            return "El campo Edad es inválido. Solo 1 o 2 dígitos numéricos.";
          }
          break;

        case "telefono":
          if (!value) return "Falta el campo Teléfono";
          if (!/^[0-9]{10}$/.test(value)) {
            return "El campo Teléfono es inválido. Debe tener exactamente 10 dígitos numéricos.";
          }
          break;

        case "email":
          value = formValuesEmail;
          if (!value) return "Falta el campo Correo Electrónico";
          if (value.length > 50) {
            return "El campo Correo Electrónico es inválido. Máximo 50 caracteres.";
          }
          // Puedes agregar una validación de formato de email aquí si lo deseas
          break;

        case "pesoPaciente":
          if (!value) return "Falta el campo Peso";
          if (!/^[0-9.]+$/.test(value)) {
            return "El campo Peso es inválido. Solo números y punto.";
          }
          break;

        case "estatura":
          if (!value) return "Falta el campo Estatura";
          if (!/^[0-9]{2,3}$/.test(value)) {
            return "El campo Estatura es inválido. Debe tener entre 2 y 3 dígitos numéricos.";
          }
          break;

        case "fechaNacimiento":
          if (!value) return "Falta el campo Fecha de Nacimiento";
          const inputDate = new Date(value);
          if (inputDate > hoy) {
            return "El campo Fecha de Nacimiento es inválido. No puede ser una fecha futura.";
          }
          break;

        case "sexo":
          if (!value) return "Falta el campo Sexo";
          break;

        case "embarazoActivo":
          if (formValues.sexo === "femenino") {
            if (!value) return "Falta el campo ¿Estás embarazada?";
          }
          break;

        case "tiempoEmbarazo":
          if (
            formValues.sexo === "femenino" &&
            formValues.embarazoActivo === "si"
          ) {
            if (!value) return "Falta el campo Tiempo de embarazo (semanas)";
            if (!/^[0-9]{1,2}$/.test(value)) {
              return "El campo Tiempo de embarazo (semanas) es inválido. Solo 1 o 2 dígitos numéricos.";
            }
          }
          break;

        default:
          break;
      }
      return null;
    };

    const fieldsToValidate = [
      "nombrePaciente",
      "apellidoPaciente",
      "edad",
      "telefono",
      "email",
      "pesoPaciente",
      "estatura",
      "fechaNacimiento",
      "sexo",
      "embarazoActivo",
      "tiempoEmbarazo",
    ];

    const validateAll = () => {
      for (let field of fieldsToValidate) {
        const error = validateField(field, formValues[field]);
        if (error) {
          setFirstValidationError(field);
          setErrorMessage(error);
          setOpenError(true);
          return false;
        }
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        const isValid = validateAll();
        if (isValid) {
          setErrorMessage("Validación exitosa. Puedes avanzar.");
          setOpenError(true);
        }
        return isValid;
      },
    }));

    return (
      <>
        <Fade in={true}>
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: 3,
                fontWeight: "bold",
                color: "primary.main",
              }}
            >
              Proporciona tu información básica y datos de contacto, por favor.
            </Typography>
          </div>
        </Fade>
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nombre(s)"
                variant="outlined"
                name="nombrePaciente"
                value={formValues.nombrePaciente}
                onChange={handleInputChange}
                required
                inputProps={{ maxLength: 50 }}
                onKeyPress={allowOnlyLetters}
                error={firstValidationError === "nombrePaciente"}
                helperText={
                  firstValidationError === "nombrePaciente" ? errorMessage : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Apellidos"
                variant="outlined"
                name="apellidoPaciente"
                value={formValues.apellidoPaciente}
                onChange={handleInputChange}
                required
                inputProps={{ maxLength: 50 }}
                onKeyPress={allowOnlyLetters}
                error={firstValidationError === "apellidoPaciente"}
                helperText={
                  firstValidationError === "apellidoPaciente"
                    ? errorMessage
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PeopleAlt />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Edad"
                variant="outlined"
                name="edad"
                value={formValues.edad}
                onChange={handleInputChange}
                required
                type="text"
                inputProps={{ maxLength: 2 }}
                onKeyPress={allowOnlyNumbers}
                error={firstValidationError === "edad"}
                helperText={firstValidationError === "edad" ? errorMessage : ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Cake />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Teléfono"
                variant="outlined"
                name="telefono"
                value={formValues.telefono}
                onChange={handleInputChange}
                required
                type="text"
                inputProps={{ maxLength: 10 }}
                onKeyPress={allowOnlyNumbers}
                error={firstValidationError === "telefono"}
                helperText={
                  firstValidationError === "telefono" ? errorMessage : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                name="email"
                defaultValue={formValuesEmail}
                value={formValuesEmail}
                onChange={handleInputChange}
                required
                disabled
                type="email"
                inputProps={{ maxLength: 50 }}
                error={firstValidationError === "email"}
                helperText={
                  firstValidationError === "email" ? errorMessage : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Peso (Kg)"
                variant="outlined"
                name="pesoPaciente"
                value={formValues.pesoPaciente}
                onChange={handleInputChange}
                required
                type="text"
                inputProps={{ maxLength: 6 }}
                onKeyPress={allowNumbersAndDot}
                error={firstValidationError === "pesoPaciente"}
                helperText={
                  firstValidationError === "pesoPaciente"
                    ? errorMessage
                    : "Por favor, ingresa tu peso en kilogramos (Kg), por ejemplo: 80.5"
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FitnessCenter />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body2" color="text.secondary">
                        Kg
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Estatura (cm)"
                variant="outlined"
                name="estatura"
                value={formValues.estatura}
                onChange={handleInputChange}
                required
                type="text"
                inputProps={{ maxLength: 3 }}
                onKeyPress={allowOnlyNumbers}
                error={firstValidationError === "estatura"}
                helperText={
                  firstValidationError === "estatura"
                    ? errorMessage
                    : "Por favor, ingresa tu estatura en centímetros (cm), por ejemplo: 174"
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Straighten />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body2" color="text.secondary">
                        Cm
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha de Nacimiento"
                variant="outlined"
                name="fechaNacimiento"
                value={formValues.fechaNacimiento}
                onChange={handleInputChange}
                required
                type="date"
                error={firstValidationError === "fechaNacimiento"}
                helperText={
                  firstValidationError === "fechaNacimiento" ? errorMessage : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true, // Asegura que la etiqueta no se sobreponga
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                component="fieldset"
                required
                sx={{ marginTop: 2 }}
                error={firstValidationError === "sexo"}
              >
                <FormLabel component="legend">Sexo</FormLabel>
                <RadioGroup
                  name="sexo"
                  value={formValues.sexo}
                  onChange={handleInputChange}
                  row
                >
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Masculino"
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Femenino"
                  />
                </RadioGroup>
                {firstValidationError === "sexo" && (
                  <FormHelperText>Por favor selecciona tu sexo.</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {formValues.sexo === "F" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl
                    component="fieldset"
                    required
                    sx={{ marginTop: 2 }}
                    error={firstValidationError === "embarazoActivo"}
                  >
                    <FormLabel component="legend">¿Estás embarazada?</FormLabel>
                    <RadioGroup
                      name="embarazoActivo"
                      value={formValues.embarazoActivo}
                      onChange={handleInputChange}
                      row
                    >
                      <FormControlLabel
                        value="si"
                        control={<Radio />}
                        label="Sí"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {firstValidationError === "embarazoActivo" && (
                      <FormHelperText>
                        Por favor indica si estás embarazada.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {formValues.embarazoActivo === "si" && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Tiempo de embarazo (semanas)"
                      variant="outlined"
                      name="tiempoEmbarazo"
                      value={formValues.tiempoEmbarazo}
                      onChange={handleInputChange}
                      required
                      type="text"
                      inputProps={{ maxLength: 2 }}
                      onKeyPress={allowOnlyNumbers}
                      error={firstValidationError === "tiempoEmbarazo"}
                      helperText={
                        firstValidationError === "tiempoEmbarazo"
                          ? errorMessage
                          : ""
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccessTime />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
          message={errorMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseError}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </>
    );
  }
);

export default Paso1InformacionBasica;
